<template>
	<section id="dashboard-analytics">
		<!-- <b-row class="match-height">
			<b-col lg="6" sm="6">
				<statistic-card-with-area-chart
					v-if="data.subscribersGained"
					icon="UsersIcon"
					:statistic="
						kFormatter(data.subscribersGained.analyticsData.subscribers)
					"
					statistic-title="Subscribers Gained"
					:chart-data="data.subscribersGained.series"
				/>
			</b-col>
			<b-col lg="6" sm="6">
				<statistic-card-with-area-chart
					v-if="data.ordersRecevied"
					icon="PackageIcon"
					color="warning"
					:statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
					statistic-title="Orders Received"
					:chart-data="data.ordersRecevied.series"
				/>
			</b-col>
		</b-row>

		<b-row class="match-height">
			<b-col cols="12">
				<analytics-avg-sessions :data="data.avgSessions" />
			</b-col>
		</b-row> -->
		<!-- {{dataDeposit}} -->
		<div style="display: flex">
      <b-col lg="6">
        <div v-if="isDataLoaded">
          <chartjs-doughnut-chart :users="users" />
        </div>
        <div v-else>
          <!-- Show a loading indicator here -->
        </div>
      </b-col>
      <b-col lg="6">
        <div v-if="isDataLoaded">
          <ChartBarUserRegister :chartDataUserRegister="chartDataUserRegister" />
        </div>
        <div v-else></div>
      </b-col>
    </div>
		<b-row>
			<b-col lg="6">
				<div v-if="isWithdrawals">
					<ChartjsBarChartDepositWithdrawal :chartDepositAndWithdrawal="chartDepositAndWithdrawal" />
				</div>
				<div v-else></div>
			</b-col>
			<b-col lg="6">
				<div v-if="isDataLoaded">
					<ChartBarTxnReportTime :chartDataTxnReportTime="chartDataTxnReportTime" />
				</div>
				<div v-else></div>
			</b-col>
			<!-- <b-col lg="6">
				<div v-if="isDataLoaded">
					<chartjs-bar-chart :chartDataWithdrawals="chartDataWithdrawals" />
				</div>
				<div v-else></div>
			</b-col> -->
		</b-row>
		<b-row>
			<b-col lg="6">
				<div v-if="isDataLoaded">
				<ChartBarUserLogin :chartDataUserLogin="chartDataUserLogin" />
				</div>
				<div v-else></div>
			</b-col>
		</b-row>

		<!-- <canvas ref="chart"></canvas> -->

		<!-- <b-row>
			<b-col cols="12">
				<users-list />
			</b-col>
		</b-row> -->
		<!-- {{dataWithdrawals}} -->
	</section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import store from "@/store";
import cardTransactionStoreModule from "@/views/payments/card-transaction/cardTransactionStoreModule";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import AnalyticsAvgSessions from "./AnalyticsAvgSessions.vue";
import ChartjsDoughnutChart from "@/views/charts-and-maps/charts/chartjs/ChartjsDoughnutChart.vue";
import ChartjsBarChart from "@/views/charts-and-maps/charts/chartjs/ChartjsBarChart.vue";
import ChartjsBarChartDeposit from "@/views/charts-and-maps/charts/chartjs/ChartjsBarChartDeposit.vue";
import ChartjsBarChartDepositWithdrawal from "@/views/charts-and-maps/charts/chartjs/ChartjsBarChartDepositWithdrawal.vue";
import {
	numberFormat,
} from "@core/utils/filter";
import { $themeColors } from "@themeConfig";
import Chart from "chart.js";
import ChartBarUserRegister from "@/views/charts-and-maps/charts/chartjs/ChartBarUserRegister";
import ChartBarUserLogin from "@/views/charts-and-maps/charts/chartjs/ChartBarUserLogin";
import ChartBarTxnReportTime from "@/views/charts-and-maps/charts/chartjs/ChartBarTxnReportTime";

export default {
	components: {
    ChartBarUserLogin,
	ChartBarTxnReportTime,
    ChartBarUserRegister,
	BRow,
    BCol,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
		ChartjsDoughnutChart,
    // Chartjs,
    ChartjsBarChart,
    ChartjsBarChartDeposit,
	ChartjsBarChartDepositWithdrawal,
    // ChartjsDoughnutChart,
		// ChartjsBarChart,
		// UsersList,
	},
	created() {
		this.fetchData();
	},

	methods: {
		kFormatter,
		fetchData() {
			store.dispatch("card-transaction/fetchDataChart").then((response) => {
				this.users = response.data.data.users;
				this.deposits = response.data.data.deposits;
				this.withdrawals = response.data.data.withdrawals;
				this.userRegisterDaily = response.data.data.user_register_daily;
				this.userLoginDaily = response.data.data.user_login_daily;
				const dates = Object.keys(this.withdrawals).sort();
				const statusListWithdrawals = this.withdrawals[dates[0]].map(
					(item) => item.txn_status_name
				);
				const statusListDeposits = this.deposits[dates[0]].map(
					(item) => item.txn_status_name
				);
				const chartDataWithdrawals = {
					labels: dates,
					datasets: statusListWithdrawals.map((status) => {
						return {
							label: status,
							data: dates.map((date) => {
								const txn = this.withdrawals[date].find(
									(item) => item.txn_status_name === status
								);
								return txn ? (txn.amount/1000) : 0;
							}),
						};
					}),
				};
				const chartDataDeposits = {
					labels: dates,
					datasets: statusListDeposits.map((status) => {
						return {
							label: status,
							data: dates.map((date) => {
								const txn = this.deposits[date].find(
									(item) => item.txn_status_name === status
								);
								return txn ? (txn.amount/1000) : 0;
							}),
							// backgroundColor: this.getRandomColor(),
							// borderWidth: 1,
						};
					}),
				};
				this.chartDataWithdrawals = chartDataWithdrawals;
				this.chartDataDeposits = chartDataDeposits;

				const chartDepositAndWithdrawal = {
					labels: dates,
					datasets: {
						withdrawal: dates.map((date) => {
								const txn = this.withdrawals[date].find(
									(item) => item.txn_status_name === 'Success'
								);
								return txn ? (txn.amount/1000) : 0;
							}),
						deposit: dates.map((date) => {
								const txn = this.deposits[date].find(
									(item) => item.txn_status_name === 'Success'
								);
								return txn ? (txn.amount/1000) : 0;
							}),
					},
				}

				this.chartDepositAndWithdrawal = chartDepositAndWithdrawal;
				
			
				const userRegisterDailySort = Object.keys(this.userRegisterDaily).sort().reduce(
					(obj, key) => {
					obj[key] = this.userRegisterDaily[key];
					return obj;
					},
					{}
				);
				this.chartDataUserRegister = {
				labels: Object.keys(userRegisterDailySort),
				dataset: Object.values(userRegisterDailySort)
				}

				const userLoginDailySort = Object.keys(this.userLoginDaily).sort().reduce(
					(obj, key) => {
					obj[key] = this.userLoginDaily[key];
					return obj;
					},
					{}
				);
				this.chartDataTxnReportTime = {
					labels: ['deposit', 'withdrawal'],
					dataset: [response.data.data.txn_report_times.deposit, response.data.data.txn_report_times.withdrawal],
					color: [response.data.data.txn_report_times.deposit > 5 ? "#f08687" : '#4bd88a', response.data.data.txn_report_times.withdrawal > 5 ? "#f08687" : '#4bd88a']
				}
				this.chartDataUserLogin = {
					labels: Object.keys(userLoginDailySort),
					dataset: Object.values(userLoginDailySort)
				}

				this.isDataLoaded = true;
				this.isWithdrawals = true;
				this.isDeposits = true;
			});
		},
	},
	data() {
		return {
			users: null,
			chartData: null,
			chartOptions: {},
			isDataLoaded: false,
			isWithdrawals: false,
			isDeposits: false,
			deposits: null,
			withdrawals: null,
			userRegisterDaily: null,
			userLoginDaily: null,
			dates: [],
			chartDataWithdrawals: {},
      		chartDataUserLogin: {},
			chartDataDeposits: {},
			chartDepositAndWithdrawal: {},
      		chartDataUserRegister: {},
			chartDataTxnReportTime: {}
		};
	},
	watch: {
		users: function (newVal) {
			this.isDataLoaded = true;
		},
		chartDataWithdrawals: function (newVal) {
			this.isWithdrawals = true;
		},
		chartDataDeposits: function (newVal) {
			this.isDeposits = true;
		},
		deep: true,
		immediate: true,
	},
	//  watch: {
	//   chartDataWithdrawals: function (newVal) {
	//     this.isWithdrawals = true;
	//   },
	//   immediate: true,
	// },
	setup() {
		// const chartjsDataChart = ref([]);

		if (!store.hasModule("card-transaction"))
			store.registerModule("card-transaction", cardTransactionStoreModule);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule("card-transaction"))
				store.unregisterModule("card-transaction");
		});

		return {numberFormat};
	},
};
</script>
